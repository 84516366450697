.resize-marker {
  position: absolute;
  background-color: rgba(180, 0, 0);
  opacity: 0.7;
  z-index: 2;
}

.resize-marker:hover {
  opacity: 1;
}
