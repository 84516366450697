.page-content-container {
  background-color: #fff;
  min-height: calc(100vh - 11em);
}

.page {
  margin: 1em;
}

.app-container {
  min-height: 100vh;
}
