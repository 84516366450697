.label-editor {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 5px 5px 2px 5px;
  line-height: 1;
  color: #000;
  z-index: 3;
}

.label-edit,
.label-edit:active,
.label-edit:focus {
  border: none;
  text-align: center;
  outline: none;
  background-color: rgba(255, 255, 255, 0.4);
}

.hidden-label-editor-content {
  position: fixed;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 13px;
  white-space: pre;
  opacity: 0;
  z-index: 0;
  top: 0;
  left: 0;
}
