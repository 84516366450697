.tick-line {
  position: absolute;
  background-color: rgba(255, 94, 123);
  z-index: 2;
  opacity: 0.5;
}

.tick-line-horizontal {
  cursor: col-resize;
}

.tick-line-vertical {
  cursor: row-resize;
}

.tick-label {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 5px 5px 2px 5px;
  line-height: 1;
  color: #000;
}

button.remove-tick-button {
  position: absolute;
}
