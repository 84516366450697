.icon-overlay {
  position: relative;
}

.icon-overlay > .icon {
  transition: all 0.3s ease;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  cursor: pointer;
  /* display: none; */
  opacity: 0;
}

.icon-overlay:hover > .icon {
  display: block;
  opacity: 0.7;
}
