.colorPickItem {
  width: 2em;
  height: 2em;
}

li.colorPickItem {
  padding-left: 3px;
  color: #ddd;
}

li.colorPickItem:hover {
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 2555, 0.5);
  z-index: 10;
}
