.image-thumbnail {
  margin: 5px;
}

.image-thumbnail-container {
  width: 100%;
  display: flex;
  border: 1px solid #ddd;
  border-radius: 5px;
  justify-content: center;
  padding: 5px;
}

.image-thumbnail-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
